<template>
  <b-dropdown v-bind="$attrs"
              v-if="!hideSelector"
              :size="size"
              extra-toggle-classes="nav-link-custom"
              menu-class="available-segments-dropdown-menu"
              toggle-class="border"
              :variant="variant"
              no-flip
              lazy
              right>
    <template #button-content>
      <div v-if="loading || deleteInProgress" class="position-absolute w-100 h-100 top-0 left-0 d-flex align-items-center justify-content-center">
        <fluency-icon class="rotating" type="loop"/>
      </div>
      <span :class="{ 'invisible': loading || deleteInProgress }">
        {{segmentBtnText}}
      </span>
    </template>
    <b-dropdown-header>
      <div :class="classes.header">
        <span>My Saved Filters</span>
        <t-button-inline v-if="ownedSegments.length > 0" @click="deleteMode = !deleteMode">{{deleteMode ? 'Done' : 'Delete Filters'}}</t-button-inline>
      </div>
    </b-dropdown-header>
    <template v-if="deleteMode">
      <b-dropdown-item v-for="(customerSegment, customerSegmentIndex) in ownedSegments"
                       :key="`customerSegment-${customerSegmentIndex}`"
                       :class="classes.item"
                       @click="deleteSegment(customerSegment, customerSegmentIndex)">
        <div class="d-flex align-items-center justify-content-between">
          <span>{{customerSegment.name}}</span>
          <fluency-icon type="delete"></fluency-icon>
        </div>
      </b-dropdown-item>
    </template>
    <template v-else>
      <b-dropdown-item v-for="(customerSegment, customerSegmentIndex) in ownedSegments"
                       :key="`customerSegment-${customerSegmentIndex}`"
                       :class="classes.item"
                       @click="$emit('input', customerSegment)">{{customerSegment.name}}</b-dropdown-item>
      <b-dropdown-item v-if="ownedSegments.length === 0"  :class="classes.item">
        <span :class="classes.nullItem">n/a</span>
      </b-dropdown-item>
      <b-dropdown-header>
        <div :class="classes.header">
          <span>Default Filters</span>
        </div>
      </b-dropdown-header>
      <b-dropdown-item v-for="(globalSegment, globalSegmentIndex) in globalSegments"
                       :key="`globalSegment-${globalSegmentIndex}`"
                       :class="classes.item"
                       @click="$emit('input', globalSegment)">{{globalSegment.name}}</b-dropdown-item>
      <b-dropdown-item v-if="globalSegments.length === 0"  :class="classes.item">
        <span :class="classes.nullItem">n/a</span>
      </b-dropdown-item>
      <template v-if="activeSegmentArray.length > 0">
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="clearFilters" :class="classes.item">All Accounts</b-dropdown-item>
      </template>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: 'selectAvailableSegmentsDropdown',
  props: {
    value: { // activeSegment
      type: Object
    },
    hideSelector: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    dropdownButtonTextPrefix: {
      type: String,
      default: 'Showing'
    },
    variant: {
      type: String,
      default: 'light'
    }
  },
  data () {
    return {
      deleteMode: false,
      deleteInProgress: false,
      classes: {
        header: 'd-flex align-items-center justify-content-between border-bottom',
        item: 'ml-3',
        nullItem: 'text-muted font-italic'
      }
    }
  },
  mounted () {
    this.getSavedSegments()
  },
  watch: {
    activeSegment (newValue, oldValue) {
      // if you just saved a segment and its not in the available segments
      if (this.segments?.findIndex(segment => newValue?.savedSegmentId === segment?.savedSegmentId) === -1) {
        this.getSavedSegments()
      }
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading.accountDetails
    },
    activeSegment () {
      return this.value
    },
    activeSegmentArray () {
      if (this.value) {
        return Object.keys(this.value)
      } else {
        return []
      }
    },
    segmentBtnText () {
      let text = ''
      if (this.activeSegment && Object.values(this.activeSegment).length > 0) {
        if (this.activeSegment.name) {
          text = `${this.activeSegment.name}`
        } else {
          const segDef = this.segments.find(seg => seg.savedSegmentId === this.activeSegment.savedSegmentId)
          if (segDef) {
            text = `${segDef.name}`
          }
        }
      } else if (this.activeSegment && Object.values(this.activeSegment).length > 0) {
        text = 'Unsaved'
      } else {
        text = 'All Accounts'
      }
      return `${this.dropdownButtonTextPrefix} ${text}`
    },
    segments () {
      return this.$store.getters.userSavedSegments
    },
    ownedSegments () {
      return this.segments?.filter(segment => segment.name && segment.owned === true) ?? []
    },
    globalSegments () {
      return this.segments?.filter(segment => segment.name && segment.owned === false) ?? []
    }
  },
  methods: {
    async getSavedSegments (forceFetch = false) {
      await this.$store.dispatch('fetchUserSavedSegments', { forceFetch }) // this.$res.fetch.savedSegments()
    },
    clearFilters () {
      this.$emit('clearFilters')
      // this.$store.dispatch('clearAllFilters')
    },
    async deleteSegment (segment, index) {
      this.deleteInProgress = true
      const allPolicies = await this.$res.fetch.recommendationPolicies() || []
      const attachedPolicies = allPolicies.filter(p => p.savedSegmentId === segment.savedSegmentId)
      let cfmMessage = `Are you sure you want to permanently delete the "${segment.name}" filter?`
      if (attachedPolicies.length > 0) {
        cfmMessage += `\n\n"${segment.name}" has ${attachedPolicies.length} recommendation ${attachedPolicies.length === 1 ? 'policy' : 'policies'} tied to it. ${attachedPolicies.length === 1 ? 'This' : 'These'} will also be deleted.`
      }
      const verify = await this.$confirm(cfmMessage)
      if (verify) {
        const resp = await this.$res.set.deleteSegment(segment)
        if (resp) {
          this.$toast('Filter Deleted', 'success')
          this.deleteMode = false
          // this.segments.splice(index, 1)
          this.getSavedSegments(true)

          if (this.activeSegment?.savedSegmentId === segment.savedSegmentId) {
            this.$emit('input', { })
          }
          if (attachedPolicies.length > 0) {
            const removePolicies = []
            attachedPolicies.forEach(policy => {
              policy.removed = true
              removePolicies.push(this.$res.set.recommendationPolicy(policy))
            })
            await Promise.all(removePolicies)
          }
        }
      }
      this.deleteInProgress = false
    }
  }
}
</script>

<style lang="scss">
.available-segments-dropdown-menu {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
